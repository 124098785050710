import React from 'react'
import { Routes, Route } from "react-router-dom"
import { Home } from './Home'

import { MarriageList } from './MarriageList'
import { MarriageDetail } from './components/pages/MarriageDetail'
import { ContentsList } from './ContentsList'
import { ContentsDetail } from './components/pages/ContentsDetail'
import { BlogList } from './BlogList'
import { BlogDetail } from './components/pages/BlogDetail'
import { SpecifiedPage } from './SpecifiedPage'
import { DoxProtectionPage } from './DoxProtectionPage'
import { PriceDetail } from './PriceDetail'

import { GlobalFooter } from './components/global/GlobalFooter'

function App() {
  return (
    <div className="App">
      <Routes>

        {/* index */}
        <Route path="/" element={<Home />} />

        {/* Specified Commercial Transaction Law */}
        <Route path="/specified-page" element={<SpecifiedPage />} />

        {/* profile document protection page */}
        <Route path="/dox-protection-page" element={<DoxProtectionPage />} />

        {/* PriceDetail */}
        <Route path="/price-detail" element={<PriceDetail />} />

        {/* marridge page */}
        <Route path="/marriage/list" element={<MarriageList />} />
        <Route path="/marriage/page/">
          <Route path=":id" element={<MarriageDetail />} />
        </Route>

        {/* blog */}
        <Route path="/blog/list" element={<BlogList />} />
        <Route path="/blog/page/">
          <Route path=":id" element={<BlogDetail />} />
        </Route>

        {/* contents */}
        <Route path="/contents/list" element={<ContentsList />} />
        <Route path="/contents/page/">
          <Route path=":id" element={<ContentsDetail />} />
        </Route>

        {/* blog page */}
      </Routes>

      <GlobalFooter />
    </div>
  );
}

export default App;