import React, { useEffect, useState} from 'react'
import { createClient } from 'microcms-js-sdk'
import ReactPaginate from 'react-paginate'
import { Container } from 'react-bootstrap'

import { PageHeader } from './components/pages/PageHeader'
import { BlogListBody } from './components/global/BlogListBody'

// connect microCMS
export const client = createClient({
  serviceDomain: 'ashiya-wedding',
  apiKey: process.env.REACT_APP_API_KEY ?? ""
})

// main
export const BlogList = () => {
  const [ blogList, setBlogList ] = useState<any[]>([])
  const [ currentPage, setCurrentPage ] = useState(0)
  const DISPLAY_NUM = 5

  useEffect(()=>{
    client.get({
      endpoint: 'blog',
      queries: {
        limit: 100
      }
    }).then((res: any) => setBlogList(res.contents))
  }, [])

  return (
    <>
      <PageHeader />

      <Container className="list-wrap">
        {blogList.filter((_,i) =>  i < (currentPage * DISPLAY_NUM - 1 < i && (currentPage + 1) * DISPLAY_NUM)).map((val: any)=>
          <BlogListBody val={val} />
        )}

        {/* pagination */}
        <ReactPaginate
          pageCount={Math.ceil(blogList.length / 5)}
          previousLabel={'前'}
          nextLabel={'次'}
          onPageChange={(selected)=>setCurrentPage(selected.selected)}
          disabledClassName="paginate_disabled"
          className="paginate"
        />
      </Container>
    </>
  )
}