import React from 'react'
import { MembershipList } from '../global/MembershipList'
import { Container } from 'react-bootstrap'

export const IndexMembership = () => {
  return (
    <Container>

      <div className="index-membership">

        <MembershipList
          number="1"
          title="面談"
          contents="
            面談は対面で直接お会いしております。<br />
            関東も対面面談お受けしております。（関西、関東以外のご遠方の場合のみZOOM可）<br />
            <a href='https://www.google.com/'>ご予約フォーム</a>より、可能な日時を３日程度ご記入の上ご予約をお願いします。<br />
            婚活の不安や疑問など、どんなことにもお答え致します。（しつこい入会営業は行っておりませんので、安心してお越しくださいませ）<br />
          "
        />
        <MembershipList
          number="2"
          title="ご入会"
          contents="
            入会が決まりましたら契約になります。下記の書類をご提出お願いします。<br />
            <ul>
              <li>住民票（３ヶ月以内）</li>
              <li>公的独身証明（３ヶ月以内・市役所で発行しています）</li>
              <li>現在の職業を証明するもの（保険証）</li>
              <li>最終学歴証明（卒業証明証　又は　証書）</li>
              <li>年収証明書（男性は必須、女性もプロフィールに載せる場合は必須）</li>
              <li>写真（２枚まで載せれます）</li>
              <li>有資格者はその証明証</li>
            </ul>
          "
        />
        <MembershipList
          number="3"
          title="お見合い"
          contents="
            芦屋ウェディングでは76,896名の在籍会員（IBJ在籍数、2022.3月）を自由に検索、閲覧、お申込みができます。<br />
            結婚相手を見つける為に、まずお見合いが成立することが第一目標になります。<br />
            その為には、お写真がとても大切です。<br />
            お写真１つでお見合い成立数は何倍も違うことがあります。<br />
            写真のアドバイス、お洋服のアドバイスを致します。（必要に応じて、信頼できる写真を撮ってくださる写真館をご紹介致します）<br />
            お見合いの場では<span>男性は清潔感と信頼できる雰囲気。女性は優しく穏やかで何よりも暖かい笑顔が求められます。</span><br />
            成功できるノウハウもお見合い前にアドバイス致します。<br />
            お見合い場所はホテルラウンジの喫茶室で１時間、お茶を飲みながら楽しんでください。<br />
            この場での連絡先の交換はご遠慮下さい。（連絡先の交換は芦屋ウェディングが代行致します）
          "
        />
        <MembershipList
          number="4"
          title="仮交際｜真剣交際"
          contents="
            仮交際<br />
            お見合い後、お互いがもう一度お会いしたい場合は仮交際という状態となります。<br />
            早めのデート約束をして、プロフィールだけでは分からないお互いの価値観などについて、お昼のランチなどお楽しみください。<br />
            仮交際という状態は他の方とお見合いすることも出来ますし、並行して仮交際も出来ます。<br />
            <br />
            真剣交際<br />
            仮交際でデートを重ね、お互いが結婚を前提にお付き合いしたい場合は、真剣交際という状態になります。<br />
            １人の相手と真剣に向き合って頂くので、他の方とお見合いすることは出来ません。<br />
            ２人で生活する今後のライフプランについて話をすることはもちろんのこと、交際中にご両親へのご紹介もお勧めします。<br />
            <br />
            分からないこと、困ったことなどありましたら１人で悩まないでご相談ください。親身にご相談に応じます。
          "
        />
        <MembershipList
          number="5"
          title="成婚"
          contents="
            真剣交際を経て、双方が結婚の意思を確認したら、プロポーズをして成婚退会になります。<br />
            結婚相談所における成婚とは挙式を挙げたり入籍をすることではなく、2人が結婚の意思を確認した時になります。<br />
            <br />
            お見合いの入り口は「外見」ですが、成婚の出口は「内面」です。<br />
            お互いを思いやり、助け合い、この人と人生を共にしたいと思い合えることが不可欠です。<br />
            成婚された会員様達よりクッチマンさんに出会えてよかったと多くのお声を聞いております。<br />
            それを聞きたくて頑張っております。<br />
            ご結婚が決まりましたらお祝いの品をお贈りしております。<br />
            ※成婚退会後に６ヶ月以内で破談し、再度お相手探しを行う場合は、成婚料なしのお見合い料のみで再活動可能です。
          "
        />

      </div>
    </Container>
  )
}