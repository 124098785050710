import { NavBar } from '../../components/global/NavBar'
import { Container } from 'react-bootstrap';

export const IndexHeader = () => {
  return (
    <Container fluid className="hero">
      <NavBar />

      <div className="messageBoard">
        <p>
          <h1>ドクターズサロン&nbsp;＆&nbsp;エグゼクティブ部門</h1>
          by&nbsp;芦屋ウエディング
        </p>
      </div>
    </Container>
  )
}