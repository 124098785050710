import React from 'react'
import { format } from 'date-fns'

import { FaCalendar } from 'react-icons/fa'

// date formatter
export const formatDate = (val: string) => {
  if(val === null || val === undefined){
    return '--'
  }else{
    return format(new Date(val), 'yyyy-MM-dd')
  }
}

type Props = {
  title: string
  revisedAt: string
  imgUrl: string
}

export const ChildTitle = (arg: Props) => {
  return(
    <div className="child-title">

      <div className="child-tilte-text">
        <div className="child-title-text-inner">
          <span><FaCalendar />{ formatDate(arg.revisedAt) }</span>
          <h2>{ arg.title }</h2>
        </div>
      </div>

      {/* <div className="child-title-image">
        {arg.imgUrl && <img src={arg.imgUrl} alt="eye-catch" />}
      </div> */}

    </div>
  )
}