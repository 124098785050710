import React from 'react'

import ProfileKutchman from '../../images/profile/yukiko_kutchman.jpg'
import ProfileOkukawa from '../../images/profile/mari_okukawa.jpg'
import { AboutAgency } from '../global/AboutAgency'

import { FaBook } from 'react-icons/fa'

export const IndexAbout = () => {

  // Book URL
  const book1Url = 'https://www.amazon.co.jp/dp/486628711X/'
  const book2Url = 'https://www.amazon.co.jp/dp/4907197969/'
  const nameKutchman = 'クッチマン悠基子'
  const nameOkukawa = '奥川マリ'

  return(
    <div className="index-about-wrap">
      <div className="index-about-text">

        <div className="whats-about-outer">
          <div className="whats-about-inner">
            <AboutAgency />
          </div>
        </div>

        <div className="index-about-profiles">
          <div className="profile-wrap">

            <img src={ProfileKutchman} alt="kutchman_profile_image" />

            <article className="profile-text">
              <div className="mb-2">
                <h6>{nameKutchman}</h6>
                代表のクッチマン悠基子です。<br />
                若い頃は父の影響もあり英語が大好きで、イギリスとドイツに在住しました。芦屋には住み始めて35年になりますが、静かでよい街です。結婚相談所はご縁を頂いて、2001年に開業しました。<br />
                また人間大好きな私は人の心にも関心があり、LINE社のサービスでは「悩み相談のカウンセラー」も行っております。<br /><br />
                例えどんなに成功している人でも人は１人では生きて行けません。人生は喜びを分け合ったり、弱音を話せたり、支え合えるパートナーがいると勇気が湧いてきます。芦屋ウェディングのモットーは「運命のお相手は必ずいる」と「信頼関係」です。一人で悩まないで一緒に考えさせてください。そして「人生を前に進めていく幸せな結婚」のお手伝いができましたら嬉しく思います。
              </div>
              <div className="mb-2">
                <strong>◆&nbsp;資格・経歴</strong><br />
                認定心理士、武蔵野大学　人間学部卒<br />
                関西カウンセリングセンター・元上級心理臨床カウンセラー（６年コース修了）<br />
                TA交流分析・初級、アドバンス、インストラクター（３年コース修了）
              </div>
              <div className="mb-2">
                <strong>◆&nbsp;著書</strong>
                <ul>
                  <li>
                    <a href={book1Url} target="_blank" rel = "noreferrer">
                      <FaBook />&nbsp;
                      「お見合い3000件の同席から見えてきたもの」&times;「心理学とスピリチュアル」&nbsp;2018/10/13
                    </a>
                  </li>
                  <li>
                    <a href={book2Url} target="_blank" rel = "noreferrer">
                      <FaBook />&nbsp;
                      あなたが結婚できない5つの理由～運命は変えられる～&nbsp;2015/12/3
                    </a>
                  </li>
                </ul>
              </div>
            </article>
          </div>

          <div className="profile-wrap">
            <img src={ProfileOkukawa} alt="okukawa_profile_image" />
            <article className="profile-text">
              <h6>{nameOkukawa}</h6>
              <p>
                芦屋ウェディングの奥川マリと申します。<br />
                日々充実した生活を送りながらもふっと気がつくと良縁に恵まれないまま年を重ねていた。。<br />
                そんな想いをお持ちの方も多いのではないでしょうか？<br />
                私も数年前まではそんな想いを持つ一人でした。<br />
                <br />
                そんな中、出会ったのが芦屋ウェディングのクッチマン先生でした。<br />
                私の想いを尊重しながらも深い経験に基づく率直な意見、的確なアドバイスをくださったお蔭で、交際がスムーズに進み、無事に成婚することができました。<br />
                婚活を通じて感じたことは、サポートしてくれる仲人も重要だということです。<br />
                婚活をしていると迷ったり継続できるかと不安になったり、一人だと負けてしまいそうになるときもあると思います。<br />
                そんな時、親身になってくれる存在は大きいと思います。<br />
                <br />
                「お見合い結婚経験者」ならではのサポートで皆さまを応援しております。<br />
                お見合いを楽しみながら前進出来ますように、是非、一緒に頑張りましょう！
              </p>
            </article>
          </div>
        </div>

      </div>
    </div>
  )
}