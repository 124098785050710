import React from 'react'
import { FaChessKing } from 'react-icons/fa'

type Props = {
  name: string
  age: number
  job: string
}

export const ManProfile = ({name, age, job}: Props) => {
  return(
    <>
      <FaChessKing className="profile-icon" />
      <span>{name}&nbsp;({age}歳:{job})</span>
    </>
  )
}