import React from 'react'

type Props = {
  title: string;
}

export const SectionTitle = ({title}: Props) => {
  return(
    <div className="section-wrap my-5">
      <div className="section-title">
        {title}
      </div>
    </div>
  )
}