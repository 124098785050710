import React from 'react'
import { Container } from 'react-bootstrap'
import { AndMore } from '../global/AndMore'

import {
  FaCamera,
  FaPhone,
  FaEnvelope,
  FaVideo,
  FaPeopleArrows
} from 'react-icons/fa'

export const IndexCourse = () => {
  return(
    <Container className="index-course-wrap">

      <div className="mb-5">
        <h5>医師、歯科医師A（男性）</h5>
          <table>
            <tbody>
              <tr>
                <td>入会金</td>
                <td>￥30,000</td>
              </tr>
              <tr>
                <td>登録料</td>
                <td>￥20,000</td>
              </tr>
              <tr>
                <td>活動費</td>
                <td>無料</td>
              </tr>
              <tr>
                <td>会費</td>
                <td>無料</td>
              </tr>
              <tr>
                <td>データ使用料</td>
                <td>無料</td>
              </tr>
              <tr>
                <td>お見合い料</td>
                <td>￥10,000</td>
              </tr>
              <tr>
                <td>お見合いの調整</td>
                <td>有</td>
              </tr>
              <tr>
                <td>お席の予約</td>
                <td>有</td>
              </tr>
              <tr>
                <td>お立ち会い</td>
                <td>有</td>
              </tr>
            </tbody>
          </table>
          <div className="annual-income">
          対象：医師、歯科医師、又は　弁護士、大学教授、薬剤師、実業家、会社員、有資格者　等<br />
          （年収：900万円以上の方）<br />
          お見合い申込み可能数（200件/月）
          </div>
      </div>

      <div className="mb-5">
        <h5>医師、歯科医師B（女性）</h5>
          <table>
            <tbody>
              <tr>
                <td>入会金</td>
                <td>￥30,000</td>
              </tr>
              <tr>
                <td>登録料</td>
                <td>￥20,000</td>
              </tr>
              <tr>
                <td>活動費</td>
                <td>￥30,000</td>
              </tr>
              <tr>
                <td>月会費</td>
                <td>無料</td>
              </tr>
              <tr>
                <td>データ使用料</td>
                <td>無料</td>
              </tr>
              <tr>
                <td>お見合い料</td>
                <td>￥10,000</td>
              </tr>
              <tr>
                <td>お見合いの調整</td>
                <td>有</td>
              </tr>
              <tr>
                <td>お席の予約</td>
                <td>有</td>
              </tr>
              <tr>
                <td>お立ち会い</td>
                <td>有</td>
              </tr>
              <tr>
                <td>お見合い申し込み可能数</td>
                <td>200件/月</td>
              </tr>
            </tbody>
          </table>
          <div className="annual-income">
            対象：医師、歯科医師
          </div>
      </div>

      <div className="mb-5">
        <h5>セレブリティ（女性）</h5>
          <table>
            <tbody>
              <tr>
                <td>入会金</td>
                <td>￥30,000</td>
              </tr>
              <tr>
                <td>登録料</td>
                <td>￥20,000</td>
              </tr>
              <tr>
                <td>活動費</td>
                <td>￥40,000</td>
              </tr>
              <tr>
                <td>月会費</td>
                <td>無料</td>
              </tr>
              <tr>
                <td>データ使用料</td>
                <td>無料</td>
              </tr>
              <tr>
                <td>お見合い料</td>
                <td>￥10,000〜￥15,000</td>
              </tr>
              <tr>
                <td>お見合いの調整</td>
                <td>有</td>
              </tr>
              <tr>
                <td>お席の予約</td>
                <td>有</td>
              </tr>
              <tr>
                <td>お立ち会い</td>
                <td>有</td>
              </tr>
              <tr>
                <td>お見合い申し込み可能数</td>
                <td>200件/月</td>
              </tr>
            </tbody>
          </table>
          <div className="annual-income">
            対象 ： 医師、歯科医師、その他　年収900万円以上の方との結婚を希望の方
          </div>
      </div>

      <hr />

      <div className="mb-5 price-description">
        <ul>
          <li>＊　お立ち会い、同席は大阪近辺、三宮近辺は無料、その他の地域は交通費実費を頂きます。（お立ち会い、同席はご希望に添います。）</li>
          <li>＊　全てのコースは活動期間は１年間です。その後　更新料￥30,000で活動継続可能です。</li>
          <li>＊　交際が順調に進み成婚になりますと医師、歯科医師A は￥350,000、Bは￥320,000、セレブリティ（女性）は￥260,000〜￥530,000を成婚料として頂きます。</li>
          <li>＊　お見合い日から交際３ヶ月を目処に結婚へ進むのか、他のお相手を探すのか決めてください。<br />
                  交際３ヶ月が過ぎますと交際料￥10,000/月が発生します。交際期間は最長は６ヶ月まで。それを過ぎても交際をご希望の場合、交際継続として成婚料を頂きます。
          </li>
          <li>＊　結婚相談所の「成婚」とは入籍や挙式ではなく、双方が結婚を目指すと決めた時になります。</li>
        </ul>
      </div>

      {/* Service */}
      <div className="service">
        <h5>Service</h5>
        <ul>
          <li><FaVideo />ビデオチャット</li>
          <li><FaPhone />電話相談</li>
          <li><FaEnvelope />メール相談</li>
          <li><FaPeopleArrows />対面相談</li>
          <li><FaCamera />写真アドバイス</li>
        </ul>
      </div>

      <AndMore url='/price-detail' />

    </Container>
  )
}