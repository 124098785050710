import { NavBar } from '../../components/global/NavBar'
import { Container } from 'react-bootstrap';

export const PageHeader = () => {
  return (
    <Container fluid className="page-hero mb-3">
      <NavBar />

      <div className="messageBoard">
        <p>
          「愛していますか？」<br />
          「愛されていますか？」<br />
          「心は満足していますか？」<br />
          幸せを掴むお手伝いをしています
        </p>
      </div>
    </Container>
  )
}