import React from 'react'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Awards1 from '../../images/awards/20071002.jpg'
import Awards2 from '../../images/awards/badge2022.jpg'
import Awards3 from '../../images/awards/kaikinsyo.jpg'
import Awards4 from '../../images/awards/badge2023.jpg'

export const IndexAwards = () => {

  return (
    <>
      <Row xs={1} md={3} className="index-awards">
        <Col>
          <img src={Awards1} alt='award' width='100%' />
          <small>IBJ功労賞</small>
        </Col>
        <Col>
          <img src={Awards4} alt='award4' width='100%' />
          <small>IBJ AWARD 2023</small>
        </Col>
        <Col>
          <img src={Awards2} alt='award2' width='100%' />
          <small>IBJ AWARD 2022</small>
        </Col>
        <Col>
          <img src={Awards3} alt='award3' width='100%' />
          <small>定例会皆勤賞</small>
        </Col>
      </Row>
    </>
  )
}