import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createClient } from 'microcms-js-sdk'
import { format } from 'date-fns'

import { PageHeader } from '../pages/PageHeader'
import { ChildTitle } from '../global/ChildTitle'
import { ChildBody } from '../global/ChildBody'

import { Container } from 'react-bootstrap'

export const client = createClient({
  serviceDomain: 'ashiya-wedding',
  apiKey: process.env.REACT_APP_API_KEY ?? ""
})

// date formatter
export const formatDate = (val: string) => {
  if(val === null || val === undefined){
    return '--'
  }else{
    return format(new Date(val), 'yyyy-MM-dd')
  }
}

type Response = {
  thumbnail: { url: string }
  title: string
  revisedAt: string
  contents: string
}

// main
export const BlogDetail = () => {

  const [ blogDetail, setBlogDetail ] = useState<Response>()
  let {id} = useParams()

  useEffect(()=>{
    client.get({
      endpoint: 'blog',
      contentId: id
    }).then((res: any) => setBlogDetail(res));
  }, [id])

  return (
    <>
      <PageHeader />

      <Container>

        { blogDetail &&
          <ChildTitle
            title={ blogDetail?.title }
            revisedAt={ blogDetail?.revisedAt }
            imgUrl={ blogDetail?.thumbnail?.url }
          />
        }

        { blogDetail &&
          <ChildBody
            contents={ blogDetail?.contents }
          />
        }

      </Container>

    </>
  )
}