import {
  Row,
  Col
} from 'react-bootstrap';

export const IndexGenderBoard = () => {
  return(
    <div className="mb-5">
      <Row>
        <Col
          xs={10} sm={10} md={6} lg={6}
          className="gender-board-men d-flex align-items-center justify-content-end"
        >
          <div>
            <h2 className="gender-text">Gentlemen</h2>
            <h3 className="gender-section">男性の結婚条件</h3>
            <ul>
              <li>定職・安定収入がある</li>
              <li>一生を添い遂げたい女性を探している</li>
            </ul>

            <h3 className="gender-section">女性が求める男性像</h3>
            <ul>
              <li>優しい人</li>
              <li>頼りがいがある</li>
              <li>誠実で、あたたかい家庭を築けそうな人</li>
            </ul>

            <p>
              男性は恋愛と結婚は別とよく言われます<br />
              貴方の希望の女性像をお伺いし、必要に応じてアドバイスも致します。
            </p>
          </div>
        </Col>

        <Col
          xs={10} sm={10} md={6} lg={6}
          className="gender-board-women d-flex align-items-center justify-content-start"
        >
          <div>
            <h2 className="gender-text">Women</h2>
            <h3 className="gender-section">女性の結婚条件</h3>
            <ul>
              <li>心身ともに健康である</li>
              <li>結婚願望がある</li>
            </ul>

            <h3 className="gender-section">男性が求める女性像</h3>
            <ul>
              <li>優しい人</li>
              <li>思いやりのある人</li>
              <li>明るい家庭を築けそうな人</li>
            </ul>

            <p>
              「素敵な男性と出会いたい」「どのように男性とお付き合いすればいいのか」<br />
              今までの経験を活かし、成婚まで手厚くサポート致します。
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
}