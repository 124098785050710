import CounselingBanner from '../../images/banner/counseling_banner.jpg'
import CounselingBannerSp from '../../images/banner/counseling_banner_sp.jpg'
// import IbjBanner from '../../images/banner/ibj_banner.jpg'
// import IbjBannerSp from '../../images/banner/ibj_banner_sp.jpg'
import MiniIbjBanner from '../../images/banner/mini_ibj_banner.png'

export const IndexBannerArea = () => {

  const ibjUrl = "https://www.ibjapan.com/"
  const contactUrl = "https://www.ashiyawedding.com/%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B/"

  return (
    <>

      <div className="index-banner-area">
        <div className="pc mb-3">
          <a href={contactUrl} >
            <img src={CounselingBanner} alt="councelling-banner" width="80%" />
          </a>
        </div>

        <div className="sp mb-3">
          <a href={contactUrl} >
           <img src={CounselingBannerSp} alt="councelling-banner_sp" width="100%" />
          </a>
        </div>

        <a href={ibjUrl}>
          <img src={MiniIbjBanner} alt="ibjLink" className="mb-3" />
        </a>

      </div>

    </>
  )
}