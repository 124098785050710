import React from 'react'

type Props = {
  contents: string
}

export const ChildBody = (arg: Props) => {
  return(
    <div className="child-body"
      dangerouslySetInnerHTML={{ __html: arg.contents }}
    ></div>
  )
}