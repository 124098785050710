import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { createClient } from 'microcms-js-sdk'
import { Row, Col } from 'react-bootstrap'

import { AndMore } from '../global/AndMore'
import { ManProfile } from '../global/ManProfile'
import { WomanProfile } from '../global/WomanProfile'

export const client = createClient({
  serviceDomain: 'ashiya-wedding',
  apiKey: process.env.REACT_APP_API_KEY ?? "",
});

export const IndexMarriage = () => {
  const [ marriageList, setMarriageList ] = useState([])

  useEffect(()=>{
    client.get({
      endpoint: 'marriage',
      queries: {limit: 6}
    }).then((res: any) => setMarriageList(res.contents))
  }, [])

  const textFormat = (arg: string) => {
    return arg.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'').substr(0, 250) + '...'
  }

  return(
    <Row className="index-marriage">
      {marriageList.map((val: any, i: number) =>
        <Col cols={12} md={6} xs={12} key={i}>
          <Link to={'/marriage/page/' + val.id}>
            <div className="card">
              <img src={val.thumbnail.url} height="180px" alt="marriageImage" />
              <article>
                <div className="text-center">
                  <ManProfile
                    name={val.man_name}
                    age={val.man_age}
                    job={val.man_job}
                  />
                  <span className="px-2">｜</span>
                  <WomanProfile
                    name={val.woman_name}
                    age={val.woman_age}
                    job={val.woman_job}
                  />
                </div>
                <hr />
                <p dangerouslySetInnerHTML={{__html: textFormat(val.contents) }}></p>
              </article>
            </div>
          </Link>
        </Col>
      )}
      <AndMore url="marriage/list" />
    </Row>
  )
}