import React from 'react'

type Props = {
  number: string;
  title: string;
  contents: string;
}

export const MembershipList = ({number, title, contents}: Props) => {
  return(
    <div className="membership-wrap">
      <article className="membership-list">
        <h4>
          <span className="membership-step">Step</span>
          <span className="membership-step-number">{number}</span>
          <span>{title}</span>
        </h4>
        <p dangerouslySetInnerHTML={{ __html: contents }} className="membership-step-content"></p>
      </article>
    </div>
  )
}