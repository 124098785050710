import React from 'react'

import { IndexHeader } from './components/pages/IndexHeader'
import { IndexGenderBoard } from './components/pages/IndexGenderBoard'
import { IndexBannerArea } from './components/global/IndexBannerArea'
import { IndexMessageBoard } from './components/pages/IndexMessageBoard'
import { SectionTitle } from './components/global/SectionTitle'
import { IndexAbout } from './components/pages/IndexAbout'
import { IndexMembership } from './components/pages/IndexMembership'
import { IndexMarriage } from './components/pages/IndexMarriage'
import { IndexCourse } from './components/pages/IndexCourse'
import { IndexAccess } from './components/pages/IndexAccess'
import { IndexNews } from './components/pages/IndexNews'
import { IndexAwards } from './components/pages/IndexAwards'
import { ContactButton } from './components/global/ContactButton'

import { Container } from 'react-bootstrap'

export const Home = () => {
  return (
    <>
      <IndexHeader />

      <Container className="px-lg-5 px-sm-2">
        <IndexGenderBoard />
        <IndexBannerArea />

        <IndexMessageBoard />

        <section>
          <SectionTitle title="AWARDS" />
        </section>
        <IndexAwards />

        <section id="index-about">
          <SectionTitle title="ABOUT" />
        </section>
        <IndexAbout />

        <section id="index-news">
          <SectionTitle title="最新情報" />
          <IndexNews />
        </section>

        <SectionTitle title="入会の流れ" />
        <IndexMembership />

        <ContactButton />

        <section id="index-marriage">
          <SectionTitle title="成婚者の声" />
        </section>
        <IndexMarriage />

        <section id="index-cource">
          <SectionTitle title="コース" />
        </section>
        <IndexCourse />

        <SectionTitle title="ACCESS" />
        <IndexAccess />

        <ContactButton />

      </Container>
    </>
  )
}