import React, { useEffect, useState} from 'react'
import { createClient } from 'microcms-js-sdk'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { PageHeader } from './components/pages/PageHeader'
import { ManProfile } from './components/global/ManProfile'
import { WomanProfile } from './components/global/WomanProfile'
// import { MarriageDate} from './components/global/MarriageDate'

// connect microCMS
export const client = createClient({
  serviceDomain: 'ashiya-wedding',
  apiKey: process.env.REACT_APP_API_KEY ?? ""
})

// contents paragraph formatter
export const contentFormat = (val: any) => {
  return val.replace(/\r?\n/g, '').substr(0, 300) + '...'
}

// main
export const MarriageList = () => {
  const [ marriageList, setMarriageList ] = useState<any[]>([])
  const [ currentPage, setCurrentPage ] = useState(0)
  const DISPLAY_NUM = 5

  useEffect(()=>{
    client.get({
      endpoint: 'marriage',
      queries: {
        limit: 100
      }
    }).then((res: any) => setMarriageList(res.contents))
  }, [])

  return (
    <>
      <PageHeader />

      <Container className="marriage-list-wrap">
        {marriageList.filter((_, i) => i < (currentPage * DISPLAY_NUM - 1 < i && (currentPage + 1) * DISPLAY_NUM)).map((val: any) =>
          <Link to={'/marriage/page/' + val.id}>
            <div className="marriage-list-body">

              {/* image */}
              <img src={val.thumbnail.url} alt="marriage-img" />

              {/* contents */}
              <div className="p-3">
                <ManProfile
                  name={val.man_name}
                  age={val.man_age}
                  job={val.man_job}
                />
                <span className="px-2">｜</span>
                <WomanProfile
                  name={val.woman_name}
                  age={val.woman_age}
                  job={val.woman_job}
                />
                {/* <MarriageDate date={val.marriage_date} /> */}
                <hr />
                <p dangerouslySetInnerHTML={{__html: contentFormat(val.contents) }}></p>
              </div>

            </div>
          </Link>
        )}

        {/* pagination */}
        <ReactPaginate
          pageCount={Math.ceil(marriageList.length / 5)}
          previousLabel={'前'}
          nextLabel={'次'}
          onPageChange={(selected)=>setCurrentPage(selected.selected)}
          disabledClassName="paginate_disabled"
          className="paginate"
        />

      </Container>
    </>
  )
}