import React, { useEffect, useState} from 'react'
import { createClient } from 'microcms-js-sdk'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { format } from 'date-fns'

import { PageHeader } from './components/pages/PageHeader'

// connect microCMS
export const client = createClient({
  serviceDomain: 'ashiya-wedding',
  apiKey: process.env.REACT_APP_API_KEY ?? ""
})

// date formatter
export const formatDate = (val: any) => {
  if(val === null || val === undefined){
    return '--'
  }else{
    return format(new Date(val), 'yyyy-MM-dd')
  }
}

// contents title formatter
export const titleFormat = (val: any) => {
  return val.replace(/\r?\n/g, '').substr(0, 50)
}

// contents paragraph formatter
export const contentFormat = (val: any) => {
  return val.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'').substr(0, 300) + '...'
}

// main
export const ContentsList = () => {
  const [ contentsList, setContentsList ] = useState<any[]>([])
  const [ currentPage, setCurrentPage ] = useState(0)
  const DISPLAY_NUM = 5

  useEffect(()=>{
    client.get({
      endpoint: 'contents',
      queries: {
        limit: 100
      }
    }).then((res: any) => setContentsList(res.contents))
  }, [])

  return (
    <>
      <PageHeader />

      <Container className="contents-list-wrap">
        {contentsList.filter((_,i) =>  i < (currentPage * DISPLAY_NUM - 1 < i && (currentPage + 1) * DISPLAY_NUM)).map((val: any)=>
          <Link to={'/contents/page/' + val.id}>
            <div className="contents-list-body">

              {/* image */}
              <aside className="p-3 d-flex align-items-center">
                <img src={val.thumbnail.url} width="180px" alt="conntens-thumbnail" />
              </aside>

              {/* contents */}
              <section className="p-3">
                <h3>{titleFormat(val.title)}</h3>
                <p dangerouslySetInnerHTML={{__html: contentFormat(val.contents) }}></p>
                <span>{formatDate(val.publishedAt)}</span>
                <div>
                  続きを読む&nbsp;⇒
                </div>
              </section>
            </div>
          </Link>
        )}

        {/* pagination */}
        <ReactPaginate
          pageCount={Math.ceil(contentsList.length / 5)}
          previousLabel={'前'}
          nextLabel={'次'}
          onPageChange={(selected)=>setCurrentPage(selected.selected)}
          disabledClassName="paginate_disabled"
          className="paginate"
        />
      </Container>
    </>
  )

}