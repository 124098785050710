import React from 'react'
import { FaChessQueen } from 'react-icons/fa'

type Props = {
  name: string
  age: number
  job: string
}

export const WomanProfile = ({name, age, job}: Props) => {
  return(
    <>
      <FaChessQueen className="profile-icon" />
      <span>{name}&nbsp;({age}歳:{job})</span>
    </>
  )
}