export const AboutAgency = () => {
  return (
    <>
      <h3>結婚相談所とは</h3>
      <p>
        世の中には様々な出会いがありますが、こと結婚を前提としたお付き合いに特化しているのが結婚相談所ではないでしょうか。<br />
        最近ではアプリの流行によって結婚相手を見つける方もいらっしゃいますが結婚相談所と違うところは「有料であること」「詳細なプロフィールの作成」「仲人がいる」というところです。<br />
        男女ともに有料であるからこそ、お互い真剣なお付き合いを探していますし、スピード感も違います。詳細なプロフィール作成により写真はもとより年収や趣味に至るまで、お話する前から相手のことがある程度わかります。<br />
        また仲人がいることによって、中々人には相談できないようなことでも、事前に相談することができます。<br />
        芦屋ウェディングでは認証心理士の資格を持つクッチマン悠基子が貴方の婚活をしっかりサポート致します。
      </p>
    </>
  )
}