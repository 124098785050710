import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { FaEnvelope } from 'react-icons/fa'

const url = "https://www.ashiyawedding.com/%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B/"

export const ContactButton = () => {
  return (
    <Container className="contact-button">
      <a href={url} className="btn"><FaEnvelope />無料相談のお申込み</a>
    </Container>
  )
}