import React, { useEffect, useState } from 'react'
import { createClient } from 'microcms-js-sdk'
import { Container } from 'react-bootstrap'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

export const client = createClient({
  serviceDomain: 'ashiya-wedding',
  apiKey: process.env.REACT_APP_API_KEY ?? "",
})

export const formatDate = (val: string) => {
  return format(new Date(val), 'yyyy-MM-dd')
}

export const IndexNews = () => {
  const [ newsList, setNewsList ] = useState<any>([])

  useEffect(()=> {
    (async() => {
      const blogResponse = await client.get({
        endpoint: 'blog',
        queries: { limit: 5 }
      })
      const marriageResponse = await client.get({
        endpoint: 'marriage',
        queries: { limit: 5 }
      })
      const contentsResponse = await client.get({
        endpoint: 'contents',
        queries: { limit: 5 }
      })

      const blogJson = Object.keys(blogResponse.contents).map((acc, val) => ({
        'id': blogResponse.contents[val].id,
        'revisedAt': blogResponse.contents[val].revisedAt,
        'title': blogResponse.contents[val].title,
        'link': '/blog/page/' + blogResponse.contents[val].id
      }), {})

      const marriageJson = Object.keys(marriageResponse.contents).map((acc, val) => ({
        'id': marriageResponse.contents[val].id,
        'revisedAt': marriageResponse.contents[val].revisedAt,
        'title': marriageResponse.contents[val].man_name + marriageResponse.contents[val].woman_name + 'ご成婚おめでとうございます！',
        'link': '/marriage/page/' + marriageResponse.contents[val].id
      }), {})

      const contentsJson = Object.keys(contentsResponse.contents).map((acc, val) => ({
        'id': contentsResponse.contents[val].id,
        'revisedAt': contentsResponse.contents[val].revisedAt,
        'title': contentsResponse.contents[val].title,
        'link': '/contents/page/' + contentsResponse.contents[val].id
      }), {})

      const allObj = [
        ...newsList,
        ...blogJson,
        ...marriageJson,
        ...contentsJson
      ]

      allObj.sort(function(a, b) {
        return (a.revisedAt > b.revisedAt) ? -1 : 1
      })

      setNewsList([...allObj])

    })()
  }, [])

  return (
    <Container className="index-news">
      <ul>
        {newsList.slice(0,5).map((val:any, i: number) =>
          <li key={i}>
            <span className="date">{ formatDate(val.revisedAt) }</span>
            <Link to={ val.link }>
              <p className="title">{ val.title }</p>
            </Link>
          </li>
        )}
      </ul>
    </Container>
  )
}