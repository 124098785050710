import React, { useEffect, useState } from 'react'
import { createClient } from 'microcms-js-sdk'

import { PageHeader } from './components/pages/PageHeader'
import { Container } from 'react-bootstrap'

export const client = createClient({
  serviceDomain: 'ashiya-wedding',
  apiKey: process.env.REACT_APP_API_KEY ?? "",
})

export const DoxProtectionPage = () => {

  const [ contents, setContents ] = useState<any>([])

  // news
  useEffect(() => {
    client.get({
      endpoint: 'contents',
      contentId: '2ag_x5296'
    }).then((res:any) => setContents(res))
  }, [])

  return (
    <>
      <PageHeader />

      <Container className="child-page-wrap">
        <h1 className="title" dangerouslySetInnerHTML={{ __html: contents.title }}></h1>
        <div className="body" dangerouslySetInnerHTML={{ __html: contents.contents }}></div>
      </Container>
    </>
  )
}