import { Container } from 'react-bootstrap'
import { ReactComponent as Logo } from '../../images/logo/logo.svg';

export const IndexAccess = () => {
  return(
    <Container fluid className="text-center">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13115.078609175855!2d135.28882523955076!3d34.73620020000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000f39deb8ff8ed%3A0x3869ee073ebceedb!2z44Kv44OD44OB44Oe44Oz4oCZcyBvZmZpY2Ug6Iqm5bGL44Km44Ko44OH44Kj44Oz44Kw!5e0!3m2!1sen!2sjp!4v1652673479917!5m2!1sen!2sjp"
        width="85%"
        height="400"
        loading="lazy"
        className="mb-5"
        title="googleMap"
      ></iframe>

      <div className="mb-4">
        <Logo />
        <p>
          〒659-0093 芦屋市船戸町5-26<br />
          TEL：0797-23-1034<br />
          11:00〜19:00<br />
          定休日：火・水
        </p>
      </div>
    </Container>
  )
}