import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  url: string;
}

export const AndMore = ({url}:Props) => {
  return(
    <div className="and-more">
      <Link to={url}>もっとみる⇒</Link>
    </div>
  )
}