import React, { useEffect } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from '../../images/logo/logo.svg';
import {
  Container,
  Navbar,
  Nav,
  Offcanvas
} from 'react-bootstrap';

export const NavBar = () =>{

  const location = useLocation()
  const handleClose = () => {
    const scrollY = window.scrollY;
    setTimeout(()=> {
      window.scrollTo(window.scrollX, scrollY);
    }, 0);
  };

  useEffect(() => {
    if (location.hash === '') {
        return;
    }
    const id = location.hash.replace(/^#/, '');
    const element = document.getElementById(id);
    if (element === null) {
        return;
    }
    const rect = element.getBoundingClientRect();
    const top = rect.top + window.pageYOffset;
    window.scrollTo(window.scrollX, top);
  }, [location]);

  return(
    <Navbar expand="true">
      <Container className="d-flex justify-content-between">

        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>

        <Navbar.Toggle className="side-nav-toggle" />

        <Navbar.Offcanvas
          placement="end"
          className="sideNav"
          onExited={handleClose}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>

          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">

              {location.pathname === '/'
                ? <Nav.Link>
                    <AnchorLink href="#index-about">About</AnchorLink>
                  </Nav.Link>
                : <Nav.Link>
                    <Link to="/#index-about">About</Link>
                  </Nav.Link>
              }

              {location.pathname === '/'
                ? <Nav.Link>
                    <AnchorLink href="#index-news">最新情報</AnchorLink>
                  </Nav.Link>
                : <Nav.Link>
                    <Link to="/#index-about">最新情報</Link>
                  </Nav.Link>
              }

              {location.pathname === '/'
                ? <Nav.Link>
                    <AnchorLink href="#index-cource">コース</AnchorLink>
                  </Nav.Link>
                : <Nav.Link>
                    <Link to="/#index-cource">コース</Link>
                  </Nav.Link>
              }

              <Nav.Link href="/contents/list">コンテンツ</Nav.Link>

              <Nav.Link href="/marriage/list">成婚者の声</Nav.Link>

              <Nav.Link href="/blog/list">ブログ</Nav.Link>
              <Nav.Link href="/dox-protection-page">個人情報取扱いについて</Nav.Link>
              <Nav.Link href='/specified-page'>特定商取引法について</Nav.Link>
            </Nav>
          </Offcanvas.Body>

        </Navbar.Offcanvas>

      </Container>
    </Navbar>
  )
}