import React from 'react'
import {format} from 'date-fns'
import { Link } from 'react-router-dom'

type Props = {
  val: any
}

// publishedAt format
export const formatDate = (arg: any) => {
  if(arg === null || arg === undefined){
    return '--'
  }else{
    return format(new Date(arg), 'yyyy-MM-dd')
  }
}

// text format
export const formatText = (arg: any) => {
  if (arg === null || arg === undefined){
    return ''
  }else{
    return arg.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'').substr(0, 250) + '...'
  }
}

// main
export const BlogListBody = ({val}:Props) => {
  return (
    <div className="news-list-body">
      <Link to={'/blog/page/' + val.id}>
        <h6>{val.title}</h6>
        <p dangerouslySetInnerHTML={{__html: formatText(val.contents) }}></p>
        <span>更新日：{formatDate(val.createdAt)}</span>
      </Link>
    </div>
  )
}