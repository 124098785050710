import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createClient } from 'microcms-js-sdk'

import { PageHeader } from './PageHeader'

import { ManProfile } from '../global/ManProfile'
import { WomanProfile } from '../global/WomanProfile'
// import { MarriageDate} from '../global/MarriageDate'

import { ChildBody } from '../global/ChildBody'

import { Container } from 'react-bootstrap'

export const client = createClient({
  serviceDomain: 'ashiya-wedding',
  apiKey: process.env.REACT_APP_API_KEY ?? "",
})

type Response = {
  id: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    revisedAt: string
    thumbnail: {
        url: string
        height: number
        width: number
    }
    man_name: string
    man_age: number
    man_job: string
    woman_name: string
    woman_age: number
    woman_job: string
    marriage_date: string
    contents: string
}

export const MarriageDetail = () => {

  const [ marriageDetail, setMarriageDetail ] = useState<Response>()
  let {id} = useParams()

  useEffect(()=>{
    client.get({
      endpoint: 'marriage',
      contentId: id
    }).then((res: any) => setMarriageDetail(res));
  }, [id])

  return (
    <>
      <PageHeader />

      <Container className="marriage-detail-wrap">

        <div className="marriage-detail-header">

          <img
            src={marriageDetail?.thumbnail?.url}
            alt="couplePhoto"
          />

          <section>
            { marriageDetail &&
              <ManProfile
                name={marriageDetail.man_name}
                age={marriageDetail.man_age}
                job={marriageDetail.man_job}
              />
            }
            <span className="px-2">｜</span>
            { marriageDetail &&
              <WomanProfile
                name={marriageDetail.woman_name}
                age={marriageDetail.woman_age}
                job={marriageDetail.woman_job}
              />
            }
            {/* { marriageDetail &&
              <MarriageDate date={marriageDetail.marriage_date} />
            } */}
          </section>
        </div>

        { marriageDetail &&
          <ChildBody
            contents={ marriageDetail?.contents }
          />
        }

      </Container>
    </>
  )
}